<!--
 * @Description: 销售合同
 * @Author: ChenXueLin
 * @Date: 2021-10-09 10:10:52
 * @LastEditTime: 2022-07-15 16:42:57
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="公司名称"
                title="公司名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="合同编号"
                title="合同编号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="业务员"
                title="业务员"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="是否营销寄送"
                title="是否营销寄送"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="typeId">
              <e6-vr-select
                v-model="searchForm.typeId"
                :data="typeList"
                placeholder="当前状态"
                title="当前状态"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="开票类型"
                title="开票类型"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="纳税人识别编号"
                title="纳税人识别编号"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="开户行名称"
                title="开户行名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="开户行账号"
                title="开户行账号"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--1" prop="orderNo">
              <el-input
                v-model="searchForm.orderNo"
                placeholder="开户行地址"
                title="开户行地址"
              ></el-input>
            </el-form-item>

            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-export_line" title="导出"></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
        >
          <!-- <el-table-column type="selection" width="40"></el-table-column> -->
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'chargeCode'">
                <el-button type="text" @click="handleGoToBillDetail(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column> -->
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>
<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "saleContract",
  data() {
    return {
      activeName: "second",
      searchForm: {
        orderNo: "",
        typeId: "",
        secondType: "",
        pageIndex: 1,
        pageSize: 20
      },
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      total: 0,
      columnData: [
        {
          fieldName: "chargeCode",
          display: true,
          fieldLabel: "开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "invoiceName",
          display: true,
          fieldLabel: "纳税人类别",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "开票类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "chargeTypeCodeName",
          display: true,
          fieldLabel: "纳税人识别编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "receiveMoneySum",
          display: true,
          fieldLabel: "开户行名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "settledStatusCodeName",
          display: true,
          fieldLabel: "开户行账号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "settledMoney",
          display: true,
          fieldLabel: "开户行地址",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "unsettledMoney",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "chargeCreatedTimeStr",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTimeStr",
          display: true,
          fieldLabel: "修改人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTimeStr",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [] // 表格数据
    };
  },
  mixins: [base, listPage, listPageReszie],

  computed: {},

  mounted() {},

  methods: {
    toDetail() {
      this.routerPush({
        name: "workOrderManage/allWorkOrder/detail",
        params: {
          refresh: true
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
